import React, { Component } from 'react';

class LeConnecteur extends Component {

  async componentDidMount() {
    await import('./le-connecteur/le-connecteur.js')
  }

  render() {
    return (
        <div className="work g-16-9 leConnecteur">
          <div className="c">
            <div className="connecteur-bg"></div>
            <svg id="nn" className="illo-nn" width="1440" height="1440" />
            <svg id="cube" className="illo-cube" width="1440" height="1440" />
            <div className="instructions">drag the shapes to interact</div>
          </div>
        </div>
    );
  }
}

export default LeConnecteur;
