export const Content = `
<p>The following essay was written and completed as part of my DNAP program at the <a href="https://esad-pyrenees.fr/" rel="noopener noreferrer nofollow">École Supérieure d'Art des Pyrénées</a> in June 2015. 
        The original text is written in french.</p>

          <section id="introduction">
            <h2>Introduction</h2>
            <p>I will address questions of aesthetics produced on the Internet, aesthetics that are often a subject of mockery: animated GIFs, space-themed wallpapers, glimmers, cat photos, rainbow gradients, and so on. In his thesis <em>Everyone Is a Graphic Designer</em>, of which a portion was published in <em>Étapes magazine</em> in 2008 <a href="https://issuu.com/yoannbertrandy" rel="noopener noreferrer nofollow">(1)</a>, Yoann Bertrandy measures the “<em>distance that distinguishes the world of authors, a learned world that sometimes looks with disdain, sometimes with amusement, at other forms of graphic design, from the world of amateurs, who know almost nothing about graphic design and even less about authorial graphic design”.</em></p>
            <p>It does indeed seem evident that the amateur knows little about the scholarly science of graphic design, but this idea of distance is, in my opinion, debatable, at least on the Internet. The aesthetics on the Internet produced by non-initiates are now clearly appropriated, both by artists and in the mainstream (massively popular, widely accepted by the public). It now has a significant influence on a certain practice of graphic design and is reappropriated for commercial purposes. How is amateur imagery produced on the Internet assimilated by the mainstream? What influence, and through what graphic forms, can amateur aesthetics on the web have on our visual landscape today and on a certain practice of graphic design? To answer these questions and delve deeper, we will also inquire about the origins of amateur aesthetics generated on the web, the channels through which it is visible today, and we will further explore a certain designer's usage in the face of the Internet.</p>
            <p>What I will term as Internet aesthetics or Internet Folklore has emerged with a generation that grew up with the Web, playing with the aesthetics of its beginnings, embracing the loud and colorful amateur side from before 2000 while simultaneously questioning and deconstructing it. Eager for new technologies, this generation isn't bound by political convictions, aside from certain movements, but rather by a shared aesthetics, a new 2.0 aesthetics.</p>
            <p>It's now apparent that this aesthetics is part of an important contemporary practice, and there is little discourse about it. In my opinion, there is a lack of theoretical distance. Being personally confronted with this aesthetics as a part of my daily visual landscape, it seems crucial to find the right words, especially since I have a strong tendency to reuse these codes in my graphic forms production, fueled by a passionate engagement with this aesthetics.</p>
            <p>In the first part, we will delve into the history and origins of an amateur aesthetics based on the Internet, its emergence, and its initial graphic signs. The second part presents a collection and analysis of various graphic signs and themes that amateurism has transformed since the early days of the Web. Additionally, it establishes connections between different movements, ranging from more amateur to artistically engaged (both artists and graphic designers), who reinterpret this vernacular language to imbue it with new meaning. Finally, the third part discusses the dissemination pathways of this Internet aesthetics and its folklore, along with a certain attitude exhibited by artists and designers within these channels.</p>
          </section>

          <section id="historical-background">
            <h2>Historical Background</h2>
            <p>Before delving into questions of aesthetics, it's important to first consider the origins of Internet aesthetics and its influence on other fields. Several factors can be noted, with the first being the public's widespread access to the Web in 1993, when non-expert AOL users began uploading their own personal web pages. A multitude of "ignorant" users started producing continuous content, which, along with the creation of amateur websites, built the Internet. The individuals who created them, along with their ideas about what the Web could be, their tastes within its technical limitations, shaped the Internet into its current form. Initially, formats were very free, expressive, personal, and incomparable to a social media profile. Personal web pages reflected the users' own tastes. As people created their pages, they had to design or collect graphic elements for them.</p>
            <p>Olia Lialina <a href="https://art.teleportacia.org/" rel="noopener noreferrer nofollow">(1)</a> is one of the pioneering net artists and theorists on the subject. She is also the author, along with Dragan Espenschied <a href="http://1x-upon.com/~despens/" rel="noopener noreferrer nofollow">(2)</a>, of the book <em>Digital Folklore</em>, which deals with the approach toward an amateur aesthetics. The book's cover features a unicorn replicated infinitely against a starry background. “<em>It represents naive nobility</em>”, explain the authors in an article for <em>Écran de Libération</em> magazine <a href="https://www.liberation.fr/ecrans/2010/02/04/le-web-c-est-folk-lol_957338/" rel="noopener noreferrer nofollow">(3)</a>. They justify it by observing that today, “<em>the idea of building a real future online has been replaced by an escape into an imaginary world</em>”.</p>
            <p>In 1995, the website Geocities opened its doors, offering the opportunity for free web hosting. It quickly became the most popular service on the web, and thousands of users created their own homepages there. In an article titled <em>A Vernacular Web</em> <a href="https://art.teleportacia.org/observation/vernacular/" rel="noopener noreferrer nofollow">(4)</a>, which was followed by <em>A Vernacular Web 2</em> <a href="http://contemporary-home-computing.org/vernacular-web-2/" rel="noopener noreferrer nofollow">(5)</a>, Olia Lialina focuses on studying the “<em>primitive Web</em>” as well as what became, in the early 2000s when web designers entered the scene, a subject of mockery. Among the studied forms, Olia talks about "under construction" signs, 3D buttons, animated GIFs that are now making a comeback, MIDI files that provided background music, and the classic "Welcome to my homepage".</p>
            <p>One of the elements mentioned in the <em>A Vernacular Web</em> series is the starry wallpaper resembling a galaxy, often animated using the GIF format and endlessly repeating along the x and y axes. According to the author, this wallpaper at the time signified “<em>a future propelling us into new dimensions</em>”. Among these personal webpage backgrounds, I also noticed many images of skies or marble textures. These backgrounds were widely used back then; however, they have mostly disappeared now. It's interesting to note, in the context of a future replaced by imagination, that today's teenage blogs have replaced these stars with animated sparkles that remind me of the toy princess aesthetic for young girls.</p>
            <p>According to the authors of <em>Digital Folklore</em>, 80% of the web resembles these aforementioned forms, but no one sees them anymore because no one takes those paths. Google doesn't index them, or they are buried deep in search results, much like in the far reaches of the universe. Perhaps these starry backgrounds were prophetic? The website <em>Internet Archaeology</em> <a href="https://web.archive.org/web/20150613051837/http://internetarchaeology.org/" rel="noopener noreferrer nofollow">(6)</a> serves as a database for all these elements that make up our past.</p>
            <p>In a lecture at La Gaîté Lyrique about <em>Digital Folklore</em> <a href="https://www.gaite-lyrique.net/index.php/evenement/digital-folklore-olia-lialina-et-dragan-espenschied" rel="noopener noreferrer nofollow">(7)</a>, Olia Lialina regards these early personal web pages as “<em>no less important than the earliest cave paintings”.</em> She adds: because “<em>the Web was personal, rich, slow, under construction. Personal web pages had an enormous impact on shaping Net culture despite their messy appearance. The Web was built entirely by users who had learned some basic HTML</em>”. Then came the dot-com era, and inevitably, the world of business and online commerce transformed the non-expert user into a consumer, making way for professionals (architects).</p>
            <p>If we view the Internet as a construction, once very kitschy and animated, an interesting parallel can be drawn with the architecture of Las Vegas. In <em>Learning from Las Vegas: The Forgotten Symbolism of Architectural Form</em>, Robert Venturi, Denise Scott Brown, and Steven Izenour discuss “<em>vernacular architecture</em>” and focus particularly on the Las Vegas Strip. They argue that “<em>the world follows function has collapsed</em>”, and graphic signs dominate space. From this perspective, we can understand why, with its starry backgrounds and animated GIFs, the digital folklore of the Internet had to refine its image. Interestingly, the under construction symbol was removed, which is counterintuitive to the market logic, as the Internet is constantly under construction. Geocities, which was acquired by Yahoo! in the meantime, had to close its doors, personal web pages were no longer relevant, and they were replaced by profiles on social networks. <em>The Contemporary Home Computing</em> <a href="http://contemporary-home-computing.org/" rel="noopener noreferrer nofollow">(8)</a> website allows the download of two terabytes of archived Geocities sites.</p>
            <p>“<em>This digital folklore is mocked for its kitsch when it's not outright considered the end of culture itself. We, on the contrary, believe that this vernacular language, created by users for users, is the most beautiful and misunderstood language of the new media. When you find a web page that touches you deeply, it will always be the work of an amateur. Amateur culture has outlasted all trends, while web designer creations are destroyed as they become outdated. These amateur pages don't top Google search results. To experience the real web, you need to surf it yourself, jumping from one link to another... and you'll find astonishing things. Most of them are useless, but who said the web was only functional?</em>” Olia Lialina</p>
            <p>In my opinion, all these historical factors and the lack of technology have clearly influenced our present visual landscape. Even though graphic design and its experts have imposed norms and standards in response to this folklore, non-initiates continue to produce content that reappropriates the Internet and its beginnings. Today, with the advent of Web 2.0 and the availability of powerful and user-friendly tools (interfaces or creation software), this non-expert production, with a generation that grew up with the Internet, has exploded. With platforms like YouTube, Facebook, or Tumblr, which replaced Geocities, amateurs generate impressive quantities of images, videos, memes, etc. that are shared across the web.</p>
            <img src="https://images.mirror-media.xyz/publication-images/8JdPSxqaZBOJ7QQJtigxg.png?height=809&amp;width=1901" class="css-ovy95q"></img>
          </section>

          <section id="internet-aesthetics">
            <h2>INTERNET AESTHETICS: CORPUS &amp; ANALYSIS</h2>
            <section id="amateurism-and-web-folklore">
              <h3>AMATEURISM &amp; WEB FOLKLORE</h3>
              <p>
        The pre-2000 web has left a significant legacy for contemporary creation. In this second part, we will analyze the codes of an Internet aesthetic ranging from surrealism to kitsch and new-age elements. By kitsch, we refer to the
        production of forms that align with how the general public defines the term – images accumulated and considered outdated by some and popular by others, according to the definition in the Petit Robert dictionary. We will use a corpus
        to illustrate and provide substance to our questions. We will select various visual movements/web cultures, more or less amateur, connected to this aesthetic. We will inquire into the codes and vernacular language of this aesthetic.
        We will identify the actors within it and explore the ways in which this aesthetic currently influences our visual landscape and a certain practice of graphic design. To conclude our analyses, we will draw connections between the
        similarities we find within the analyzed cases and establish parallels between the amateur's submission to the expert technique of a graphic designer and their deliberate choice to adopt this influence in constructing an image.
    </p>
    <p>
        We will frequently discuss the social network Tumblr and delve into it more precisely in a third part, but it's essential to define it in order to follow the argumentation. Tumblr was created in 2007 by David Spark, and it's a
        platform for posting images, videos, and texts. Operating without a hierarchy, Tumblr relies on peer validation. Widely used by teenagers (approximately 46 million profiles), Tumblr allows users to create a wall of images and
        functions through sharing. Thus, regardless of the author's intention, an image can be shared thousands of times.
    </p>
    <p>
        After Geocities and personal pages, and with the emergence of social networks like Facebook, Myspace, or Tumblr, internet users found a new place for themselves. They didn't miss the chance to perpetuate the folk, kitsch, and
        popular side of the web. If we closely examine what's happening on platforms where you can customize your profile, we can see that most of the background designs resemble those from Geocities in 1996, with an image that repeats both
        horizontally and vertically. Whether it's on networks like Myspace or Twitter, this trend is directly linked to the early days of the web, and no one seems inclined to eliminate it. When we stay within the realm of customization, as
        opposed to the clean and monotonous design of Facebook, we can still clearly distinguish between the non-expert user and the graphic designer.
    </p>
    <p>
        The perpetuation of Internet aesthetics has been diversified, with new elements and tools contributing to what I would describe as a completely unburdened inspiration. Whether it's the introduction of low-cost tools, Glitter Text
        Generator or tools like Blingee <a href="https://blingee.com/" rel="noopener noreferrer nofollow">(1)</a> that are all the rage among teenagers, this democratization has led to a flood of images circulating on social networks. For
        instance, Blingee is an online photo editor with pre-made templates and automated montages that allows users to upload their photos and create digital collages by adding various kitschy elements. It draws from the concept of
        collecting graphic shapes that pioneers of the web had. On Blingee, you can find thousands of animated GIFs, such as sparkling flowers, unicorns, butterflies, three-dimensional letters, frames to surround your composition, and more.
        All of this enables users to superimpose graphics on their images. There are numerous tools like this on the web, widely used by teenagers, and they became especially popular during the time when Myspace was still active. These
        glittering images and animations have become a hallmark of today's non-expert web, and as noted by Olia Lialina in <em>A Vernacular Web 2</em>, “
        <em>glittering is everywhere; it has become a meta-category, parasitizing all other categories of ready-made graphic forms: people, animals, clickable buttons, sex”.</em> The reason for the popularity of this type of design might
        not be the ease with which amateurs can create it but rather a desire to draw attention to oneself, as suggested by social media researcher Danah Boyd. According to Boyd, “
        <em>the flashy side of Myspace resembles the image of Las Vegas, which attracts millions of people each year</em>”. We might question whether this type of graphic design could be considered amateur user experience design. These
        forms are unique, colorful, and loud, created by self-proclaimed non-expert designers in this new digital world.
    </p>
    <p>
        Artist Molly Soda <a href="https://mollysoda.biz/" rel="noopener noreferrer nofollow">(2)</a>, in my opinion exemplifies the best use and adoption of this popular culture. An internet diva, Molly Soda is both an artist and curator.
        She creates GIFs, and her website showcases images of fast food, old photos of Britney Spears, and webcam-recorded performances posted on YouTube. Molly Soda is an expert in selfies, which she became known for through her Tumblr
        <a href="https://mollysoda.tumblr.com" rel="noopener noreferrer nofollow">(3)</a>. A selfie, as we call it, is a self-portrait taken to be posted on a social network. Very trendy across all ages, the person's arm holding the camera
        is usually visible. A selfie typically accompanies a puckered "kiss" or a neutral expression not directed at the camera. In my view, the selfie has become a contemporary element of the vernacular language specific to the web. Molly
        Soda has thus adopted the codes of a generation that exposes itself on the internet, along with its graphic signs, to cleverly promote herself on the web and gain recognition from an audience she drew inspiration from.
    </p>
    <p>
        Pinar &amp; Viola <a href="http://pinar-viola.com/" rel="noopener noreferrer nofollow">(4)</a>, another notable example and a significant reference in my work due to the quality of their projects, is a graphic design studio based in
        Amsterdam. Describing themselves as a "<em>hyper-detailed scan of our contemporary culture</em>", the studio professes a passion for the aesthetics of the "<em>self-made Internet ghetto</em>" and further claims to deeply admire the
        aesthetics generated by amateurs. Somewhat akin to the digital version of Pierre and Gilles, the two graphic designers share a similar aesthetic approach. Their collages are inspired by non-expert creations and reject an "elitist
        style". In their works, you can find images discovered on the internet after hours spent on Google Images or Tumblr. They disregard copyright, believing in the concept of “
        <em>taking from the internet to give back to the internet</em>”. Their main interest through web usage and aesthetics is social interaction through graphic design. For instance, their project <em>4EVA</em> is a series of cleverly
        crafted collages made from glittering love declarations found on social networks. These declarations are then sent by email and SMS to users who sign up on a specially created platform. This project is inspired by online adolescent
        romances that showcase intimate photos or love messages. Pinar &amp; Viola frequently work with the selfie concept explained earlier. For example, their projects <em>Optical Illusion Selfie</em> or <em>Aqua</em> involve subjectively
        printing images of corrupt politicians onto towels. The portraits are adorned with elements of the vernacular aesthetics of the internet prevalent on social networks, which are also used by politicians (particularly Twitter). The
        towels, with the added imagery, were then presented alongside selfies taken by adolescents in their bathrooms, with the towels draped over them, essentially portraying them as desirable objects.
    </p>
    <p>
        A more commercial and mainstream example is M.I.A's music video <em>XXXO</em> <a href="https://youtu.be/sfbQ5mHWkOs?si=CVWNaV2l1dEkL-fs" rel="noopener noreferrer nofollow">(5)</a>. While technically different from the loud
        adolescent collages or nostalgic GIF aesthetics of Myspace, the video effectively incorporates all the codes of this aesthetic. The pioneers of Net Art have passed on their love for digital folklore to a new generation of artists,
        often born with the internet, who continue to collect, re-contextualize, and draw inspiration from this amateur culture. In April 2012, Wired published an article by Bruce Sterling about the "New Aesthetic" wave
        <a href="https://www.wired.com/2012/04/an-essay-on-the-new-aesthetic/" rel="noopener noreferrer nofollow">(6)</a>, which would eventually be referred to as Internet Folklore, Net Art, or "Tumblr Mess". According to Sterling, this
        phenomenon is a “<em>collective intelligence</em>”, a “<em>product of modern networked culture</em>” driven by the concept of sharing (he mentions Tumblr's "Reblog" button).
    </p>
    <p>
        After discussing web vernacular in the broader sense, it's important to introduce specific cultures born on the internet that stem from the previously discussed aesthetics. The subsequent section (following the corpus) is dedicated
        to seapunk, muslim trance, and vaporwave. Cultural movements and internet-specific trends tend to have a relatively short lifespan, often pronounced dead once they are picked up by mainstream media. What fascinates me about the
        movements I'm going to present is how, when images are shared through networks, you can gradually observe an aesthetic trend evolving through its stages. The internet allows for reinterpretation and reappropriation of various
        subculture aesthetics that would have otherwise never been renewed.
    </p>
    <img src="https://images.mirror-media.xyz/publication-images/lr4H5YZQpHqb-SUh0TW0B.jpg?height=3085&amp;width=1901" class="css-ovy95q" />
            
            </section>
            <section id="tumblr-seapunk-movement">
              <h3>Tumblr Seapunk Movement</h3>
              <p>
        A movement born exclusively on the web, seapunk entered the sphere of the internet on June 1, 2011, following a tweet from musician and videomaker @LILINTERNET
        <a href="https://twitter.com/LILINTERNET" rel="noopener noreferrer nofollow">(1)</a>, stating, “Seapunk leather jacket with barnacles where the studs used to be”. It started as a big joke, but the seapunk culture was entirely born
        on the internet.
    </p>
    <p>
        Initially, seapunk was more of a viral and visual mode of expression than a music genre. Its emergence was primarily thanks to Tumblr. According to Mathieu Buard
        <a href="https://www.liberation.fr/mode/2012/06/27/couleur-punk-a-l-eau_824377/" rel="noopener noreferrer nofollow">(2)</a>, a fashion professor at the Duperré School of Applied Arts, “<em>The medium created the movement</em>”.
        Tumblr's architecture and functionality contributed to its democratization, with “
        <em>its collage paradigm and layering of images. The seapunk look was akin to a PDF file; you couldn't separate one image from the others. Young creators proposed something, whether it pleased or not, and then they moved on</em>”.
        Validation from the community played a crucial role in this collective creation. On Tumblr, there was an abundance of images identified as seapunk
        <a href="https://www.tumblr.com/search/seapunk" rel="noopener noreferrer nofollow">(3)</a>. Participants acted as content producers and followers, illustrating the participatory nature of the web. Miles Raymer, from the
        <em>Chicago Reader</em> <a href="https://chicagoreader.com/" rel="noopener noreferrer nofollow">(4)</a>, noted that “
        <em>
            what made seapunk more interesting than the rest was how it reflected the way communities functioned on Tumblr and similar spaces. It would have been almost impossible to create favorable circumstances for these disparate
            elements to come together coherently. The true center of this scene was the internet, particularly Tumblr
        </em>
        ”.
    </p>
    <p>
        Following the Tumblr buzz, music labels quickly signed seapunk artists. Among the main representatives were Coral Records Internationale in the USA, Unicorn Kid
        <a href="https://twitter.com/9lu5h" rel="noopener noreferrer nofollow">(5)</a> in England, the SeaPunk Gang
        <a href="https://web.archive.org/web/20150604070214/http://www.seapunkgang.com/" rel="noopener noreferrer nofollow">(6)</a> in Italy, and the duo Ideal Corpus
        <a href="https://soundcloud.com/idealcorpus" rel="noopener noreferrer nofollow">(7)</a> in France.
    </p>
    <p>
        For many, the precise definition of seapunk is that of a music style or a fashion trend. For Zombelle <a href="https://soundcloud.com/ZOMBELLE" rel="noopener noreferrer nofollow">(8)</a> one of the first to claim the movement on the
        internet and a self-proclaimed leader, seapunk is, above all, “<em>an ideology closely related to positivity and the creation of a fantastical reality</em>”. She describes seapunk as a convergence between the real and virtual
        worlds, where one creates the world in which they would like to live. The aesthetics of the internet align perfectly with this concept, as the web is a “<em>vast sea of information</em>”
        <a href="https://respect-mag.com/2012/11/you-never-thought-seapunk-would-take-it-this-far-zombelle-talks-azealia-banks-rihanna-the-week-the-second-internet-exploded/" rel="noopener noreferrer nofollow">(9)</a>. I have no idea how to
        quantify the "seapunk" population; by looking at the number of seapunk group fans on Twitter or Soundcloud (a social audio distribution network), we might be talking about roughly, without overestimating, a few thousand people
        scattered worldwide.
    </p>
    <p>
        Visually, a seapunk image translates into a collage, an assembly of elements forming a composition. Seapunk creates style by appropriating a language that retrieves images without hierarchy. Its iconography is essentially defined by
        images of dolphins, turquoise with touches of green, an underwater environment, and a whole kitsch arsenal from the 90s. It includes 3D geometric shapes floating in the air, Ionic columns, and statues from antiquity or the
        Renaissance, crosses, pyramids, cones, and more. These references draw from New Age, psychedelia, trance, etc. There's a mix of references, including symbols like 3D pyramids, pixelation, waves, deities, glitch, cult objects, and
        optical effects. It's also important to note that androgyny is a key element in the seapunk's identity construction. According to Mathieu Buard, “<em>seapunks exist in a sexual margin</em>”; dolphins and mermaids are perceived as
        asexual animals, “<em>simplifying gender questions, hence the lack of assertion</em>”.
    </p>
    <p>
        On the french side, we can mention the duo Ideal Corpus, with their exhibition <em>Ideal Paradise</em> <a href="https://lagad.eu/?page_id=7921" rel="noopener noreferrer nofollow">(10)</a> at the Arnaud Deschin Gallery in Marseille
        in July 2013. Like their counterparts Zombelle and UltraDemon <a href="https://soundcloud.com/fireforeffect" rel="noopener noreferrer nofollow">(11)</a> from Coral Records, the duo works in search of an ideal between the physical
        and the virtual, where multiple universes coexist. "<em>Tropicalism 3.0</em>", Ideal Corpus embodies the seapunk movement, which exists only online and from which they borrow their aesthetics. They measure their success in the
        number of clicks rather than the number of people attending their events. Ideal Corpus also speaks of ideology and utopia, a utopia that would take place on the internet. Their Photoshop collages further capture the idea of
        iconography with their borrowings from internet culture and color codes.
    </p>
    <p>Beyond its legions of fans and self-proclaimed artists, seapunk is in vogue, and the mainstream isn't hesitant to borrow from it.</p>
    <p>
        Even though, in my view, we are more in the realm of vaporwave aesthetics, and while it's easy to cry plagiarism, singer Rihanna, in her July 2012 performance for her song <em>Diamonds</em>
        <a href="https://www.youtube.com/watch?v=0-p5EbAsxUM" rel="noopener noreferrer nofollow">(12)</a>, was accused of appropriating seapunk aesthetics. In the performance, you can see Ionic columns, palm trees, Greek statues, and the
        singer is set against an underwater backdrop. This was enough to ignite the Tumblr community and seapunk artists. Personally, I find Rihanna's video closer to a New Age aesthetic that borrows from the vernacular language of
        seapunks. Still, this initial attempt was the first to introduce this subculture to the mainstream.
    </p>
    <p>
        The most significant controversy sparked on the web regarding seapunk plagiarism was caused by singer Azealia Banks, who collaborates with @LILINTERNET. In her video clip <em>Atlantis</em>
        <a href="https://www.youtube.com/watch?v=yj-xBpQ0CI0" rel="noopener noreferrer nofollow">(13)</a>, she openly admitted on Twitter that she “<em>stole this aesthetic for profit</em>". Zombelle responded that "
        <em>it's not just an internet joke</em>”. To some extent, it is, but it's primarily “<em>an observation about web culture, the development of popularity, and the internet itself</em>”. The video, directed by graffiti artist Fafi
        <a href="https://www.fafi.net/" rel="noopener noreferrer nofollow">(13)</a>, clearly borrows the aesthetics developed by seapunks. Everything is there: "bad 3D", underwater background, and maritime iconography. The dolphins have
        simply been replaced by sharks. Azealia Banks takes seapunk codes further than Rihanna did in the eyes of the general public by fully embracing these codes. According to Tom Hawking, a journalist at <em>Flavorwire</em>, “
        <em>if you have a philosophy to promote, you'll probably be happy to see your ideas spread on a large scale, but if you only have an aesthetic, you'll guard it jealously</em>”
        <a href="https://www.flavorwire.com/346153/do-seapunks-have-a-right-to-be-pissed-at-rihanna" rel="noopener noreferrer nofollow">(14)</a>.
    </p>
    <p>
        Today, the line between Tumblr seapunk teens and the artistic directors of luxury fashion houses is also thin. Versace, Proenza Schouler, and Kenzo with its <em>Marble Punch</em> collection have clearly drawn from seapunk aesthetics
        and internet folklore. As Mathieu Buard points out, “<em>if these borrowings demonstrate the relevance and symbolic power of this aesthetic, they also show that fashion designers have become consumers rather than authors</em>”.
    </p>
    <p>
        What differentiates the learned culture from web novices remains the technical aspect. There's a clear rivalry, as expressed on social networks, between the anarchy of images identified with a movement on social platforms and the
        mastery of Photoshop by art directors. What I've noticed when looking at the work of these designers or art directors responsible for these visuals is that they have no direct or indirect connection to web cultures, and they don't
        produce any other work in this vein.
    </p>
    <p>
        Proenza Schouler, a luxury brand, is a good example with their video <em>Desert Tide</em> <a href="https://www.youtube.com/watch?v=_Y41MsmI598" rel="noopener noreferrer nofollow">(15)</a>, which captures an internet-like atmosphere.
        The video, created on the online game Second Life, features virtual models dancing. Regarding this, Lazaro Hernandez, the brand's creator, notes, “
        <em>We love the rawness of the internet. The aesthetics of the internet, to us, are very contemporary. This new, super weird thing, this contemporary web stuff fascinates us</em>”.
    </p>
    <p>
        One might question the status of these new images. They don't have the same meaning since it's cleverly transformed. Some on the internet claim that the movement is already dead, but ultimately, it doesn't matter because only the
        present counts, and it is still widely disseminated and shared.
    </p>
<img src="https://images.mirror-media.xyz/publication-images/IdJ0JElg9epuBDMdlPxHX.jpg?height=3085&amp;width=1901" class="css-ovy95q" />

            </section>
            <section id="fatima-al-qadiri-and-the-muslim-trance">
              <h3>Fatima Al-Qadiri and the Muslim Trance</h3>
               <p>
        Fatima Al-Qadiri <a href="https://fatimaalqadiri.com/" rel="noopener noreferrer nofollow">(1)</a> is the originator of an aesthetic of sounds and images that has gained popularity on the web and in trendy artistic circles: Muslim
        Trance. Although she draws inspiration from a non-savvy web aesthetic, here, in contrast to what I presented earlier, it's an artist who has inspired a movement of amateurs, which in turn has influenced professionals.
    </p>
    <p>
        Fatima Al-Qadiri is a New York-based artist originally from Kuwait. Her work primarily explores the identity of young people in the Middle East, Arab culture in general, and the practices of the "new-rich" in this region of the
        world. In 2009, she began to gain attention with her series <em>Dragas</em>, which was labeled as satanic, during her exhibition <em>Goth Gulf Visual Vortex</em>
        <a href="https://www.sultangallery.com/bayt-keshkha-2009" rel="noopener noreferrer nofollow">(2)</a> at the Sultan Gallery in Kuwait City. Misunderstood by the public, she intended to reference the transformation of young Kuwaiti
        women in response to global trends.
    </p>
    <p>
        At the end of 2010, <em>Dis Magazine</em> published a music mix by Fatima Al-Qadiri, under the name Aykshay, titled <em>Muslim Trance</em>
        <a href="http://dismagazine.com/disco/mixes/10002/muslim-trance" rel="noopener noreferrer nofollow">(3)</a>, which created a buzz online. The music is a blend of Shiite and Sunni sacred chants found on the internet. The visual
        aesthetic set the tone for the trend: Arabic letters, a burqa, a photo of an overly made-up Muslim woman - a collage with classical elements of Arab culture, kitsch in an avant-garde manner, halfway between Net-Art and Islam.
        Following the success of this first mix, Fatima Al-Qadiri released a second one: <em>Muslim Trance 2</em>. The visual shows a woman in a hijab with 3D Arabic typography, all pasted onto an ocean background with an artificial orange
        sky - still in the vein of Internet Folklore from the first EP. These two images were extensively reblogged on Tumblr.
    </p>
    <p>
        Subsequently, Fatima worked with artist Tabor Robak <a href="https://www.taborrobak.com/" rel="noopener noreferrer nofollow">(4)</a> on her futuristic and mind-bending video, <em>Vatican Vibes</em>
        <a href="http://youtube.com/watch?v=hKosaf5tmpl" rel="noopener noreferrer nofollow">(5)</a>. On the other hand, Dis Magazine continued the trend with articles like <em>Dressing your Hijab</em>
        <a href="http://dismagazine.com/blog/27825/dressing-your-hijab" rel="noopener noreferrer nofollow">(6)</a> or <em>Coupling Q8</em> <a href="http://dismagazine.com/blog/12647/coupling-q8-2" rel="noopener noreferrer nofollow">(7)</a>,
        eloquently portraying a young, chic, and fashionable Arab world. The tone was set: the non-savvy masses were appropriating the aesthetic, and every day, new collages and GIFs incorporating Arabic scripts and web folklore images
        (rainbows, golden unicorns, or plastic flowers) are posted on Tumblr.
    </p>
    <p>
        After already being in the Digital Folklore trend with her clip <em>XXXO</em>, M.I.A. was the first to follow this movement. Whether it's her burqa at the Scream Awards (incorporating everything kitschy from the internet, like GIFs,
        CAPTCHA, etc.) or her video <em>Bad Girls</em> <a href="https://www.youtube.com/watch?v=2uYs0gJD-LE" rel="noopener noreferrer nofollow">(8)</a>, directed by Romain Gavras
        <a href="https://kourtrajme.com/" rel="noopener noreferrer nofollow">(9)</a>, where she is surrounded by women wearing leopard-patterned veils alongside a golden BMW and men in traditional Saudi attire. “
        <em>Arabic is in fashion, it's the new Chinese for tattoos</em>”, says Fatima Al-Qadiri on her Twitter account. “
        <em>Arabic is also the new Russian. It has a dark and bad-boy connotation that teenagers are trying to appropriate for obvious reasons</em>”.
    </p>
    <p>
        What I find fascinating about the internet is how images posted on relatively closed channels end up being shared on social networks and are completely reinterpreted by other individuals. Like Fatima Al-Qadiri, Abdullah Al Mutairi
        <a href="https://www.tumblr.com/halaga-halaqa" rel="noopener noreferrer nofollow">(9)</a> is an artist from Kuwait. He exhibited an ultra-kitsch video series
        <a href="http://vimeo.com/63465925" rel="noopener noreferrer nofollow">(10)</a> <a href="http://vimeo.com/63465926" rel="noopener noreferrer nofollow">(11)</a> at the Global Art Forum in Art Dubai
        <a href="https://artdubai.ae/" rel="noopener noreferrer nofollow">(12)</a>. Using videos taken from animated image banks with 3D elements, he creates a Muslim trance aesthetic that explores identity and the intersection of gender,
        religion, and technology in the Gulf. Very much in line with internet aesthetics and working in the vein of Fatima Al-Qadiri, with whom he occasionally collaborates on Dis Magazine, he also has a Tumblr called Halaga Halaqa, where “
        <em>Halaqa refers to a religious gathering and "Halaga" is a term for something kitschy. So, his Tumblr is a discussion about the kitschiness of religious aesthetics</em>”.
    </p>
    <p>
        We must also mention the designers Pinar &amp; Viola and their exhibition <em>Diva Opaque</em> from 2012, which, in my opinion, falls into the Muslim trance trend. This exhibition features a collection of images belonging to an
        imaginary character named Scarf_whiz80 (reminiscent of pseudonyms and Blingee aesthetics) who "glorifies scarves." On their website, they explain that it is a “<em>contradiction to the current hijab trend</em>”, that this work is a
        “<em>critique of social norms of gender, race, religion, and sexuality, including the destruction of stereotypes and ideologies</em>”, and politically engaged against Geert Wilders, an extremist Dutch politician.
    </p>
    <div contenteditable="false" draggable="true" data-theme="light" style="--mode-colors-accent: 0, 0, 0; --mode-colors-accentText: 255, 255, 255;">
        <figure class="image" contenteditable="false">
            <span class="css-aset5e"><img src="https://images.mirror-media.xyz/publication-images/SydwjmZxWx3UizWdCX-P6.jpg?height=1568&amp;width=1901" class="css-ovy95q" /></span>
            <figcaption class="caption css-1o9rk4m" contenteditable="true" data-caption="Write a caption" role="textbox" tabindex="-1"></figcaption>
        </figure>
    </div>
            </section>

            <section id="the-vaporwave">
              <h3>The Vaporwave</h3>
              <p>
        Even though seapunk was the first internet subculture to be embraced by the mainstream, it definitely won't be the last. Like seapunk, vaporwave is another visual and musical micro-movement on Tumblr, albeit with fewer dolphins.
        It's important to discuss vaporwave because, with its vernacular language, it constitutes a distinct web culture. While it's equally difficult to visually define, it's less of a joke compared to its predecessor. Its name, according
        to musician Internet Club <a href="https://internetclubdotcom.angelfire.com/" rel="noopener noreferrer nofollow">(1)</a>, is derived from the overall atmosphere of the tracks and the term "vaporware," which refers to long-promised
        software that's continually delayed. It's also called "corporate smooth jazz Window 95 pop."
    </p>
    <p>
        James Ferraro <a href="https://soundcloud.com/b-e-b-e-t-u-n-e" rel="noopener noreferrer nofollow">(2)</a>, an experimental musician, composer, and electronic music producer from the Bronx, is the vaporwave reference point with his
        2011 album <em>Far Side Virtual</em>, which he described as an “<em>opera to our consumerist civilization</em>” and “<em>16 cell phone ringtones to download</em>”
        <a href="https://thequietus.com/articles/07586-james-ferraro-far-side-virtual-interview" rel="noopener noreferrer nofollow">(3)</a>. This perfectly captures the essence of vaporwave that followed. Fatima Al-Qadari is also considered
        a pioneer of vaporwave, at least musically. Other, often anonymous, lesser-known artists, like the <em>NEW DREAMS LTD</em> <a href="https://newdreamsltd.tumblr.com/" rel="noopener noreferrer nofollow">(4)</a> project, which was
        considered defining for the scene when it was released in 2011, or the anonymous Laserdisc Visions with its subprojects <em>esc不在</em>, <em>Macintosh Plus</em>, and <em>情報デスクVIRTUAL</em>.
    </p>
    <p>
        Before delving into the visual aspect of vaporwave and to better understand it, we need to define its music. It's primarily sample-based, with looping and reverb applied to Japanese supermarket music (typically pop and lounge in
        nature), hence "capitalist music." Unlike seapunk, vaporwave is punk in its own right, driven by a political objective. It's dissenting in the sense that, by appropriating tracks that reflect the superficiality of our society, “
        <em>it could be seen as a form of accelerationism—a movement that attributes the decline of Western civilization to capitalism and prefers to accelerate that decline rather than confront it</em>”
        <a href="https://chillandrelevant.blogspot.com/2012/11/micro-genre-a-la-con-1-la-vaporwave.html" rel="noopener noreferrer nofollow">(5)</a>. It's both a critique and a capitalization.
    </p>
    <p>
        Vaporwave's aesthetic is easier to pinpoint than its music. At first glance, it shares similarities with seapunk and is directly derived from the net's underground micro-genres: graphics inspired by Windows 98 screensavers, cloudy
        horizons, a retro feel, and sculptures by David. It carries the same nostalgia for the early days of the web, minus the seashells and pastel colors, but for some reason, we decided to keep the palm trees.
    </p>
    <p>
        The images produced by anonymous "entities" lurking on the internet are kitschy and heavily rely on appropriation. Like the situationists, they create a new aesthetic opposed to the original message. Vaporwave's iconography revolves
        around obsolete technologies: VHS, audio CDs, outdated software interfaces, capitalist icons, and shapes associated with companies like AOL or Internet Explorer. The emblems of these technologies are outdated, seemingly to remind us
        of the impact technology has on our daily lives.
    </p>
    <p>
        It's worth noting that when you look at the names of artists, albums, and even song titles, they are almost all written in capital letters and often use Japanese characters. According to Michelle Lhooq, an art and culture writer for
        Vice Magazine, this is partly explained by Japan's obsession with Cyberpunk culture and because these unreadable characters “<em>make them signify a globalized and impenetrable world</em>”
        <a href="https://www.vice.com/en/article/3de8mb/is-vaporwave-the-next-seapunk" rel="noopener noreferrer nofollow">(6)</a>. In the same vein, we can also notice references to Shibuya, such as Internet Club's track
        <em>Lost in Shibuya</em> and photos of Tokyo on some album covers. Shibuya is a ward in Tokyo known for its shops, billboards, and business district. Additionally, in my research on possible connections, I came across the musical
        style Shibuya-Kei. The name Shibuya (渋谷) is combined with the Japanese word kei (系), which literally means "system". Shibuya-Kei directly originates from the ward and is believed to have been named after its shopping district
        <a href="https://imomus.com/jpop.html" rel="noopener noreferrer nofollow">(7)</a>. While I don't have information on the use of Renaissance statues, if I were to follow the reasoning of vaporwave enthusiasts, I would say that,
        unlike the haphazard appropriation of seapunk, they symbolize a cultural reference. They equate internet culture with a part of our shared history.
    </p>
    <p>
        In 2014, vaporwave is thriving. In addition to its musicians, there is a whole community of enthusiasts who have recently started sharing and even creating thousands of #vaporwave images
        <a href="https://tumblr.com/search/vaporwave" rel="noopener noreferrer nofollow">(8)</a>.
    </p>
    <img src="https://images.mirror-media.xyz/publication-images/sfemcXkrKTXs0cfJpdrSw.jpg?height=1568&amp;width=1901" class="css-ovy95q" />
            </section>
          </section>

          <section>

            <section id="fatima-al-qadiri-and-the-muslim-trance">
              <h3>Fatima Al-Qadiri and the Muslim Trance</h3>
              <p>Details of Sub content 3</p>
            </section>

          </section>
`;