import React, { createContext, useContext, useState, useEffect } from "react";
import Darkmode from "darkmode-js";

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const darkmode = new Darkmode({
    showWidget: false,
    backgroundColor: "transparent",
    mixColor: "transparent",
  });

  const toggleDarkMode = () => {
    darkmode.toggle();
    setIsDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    setIsDarkMode(darkmode.isActivated());
  }, []);

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => useContext(DarkModeContext);
