import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
import useSwiperLogic from '../../components/useSwiperLogic';
import { useDarkMode } from '../../context/DarkModeContext';

const HybridNouns = ({ isCurrent }) => {
  const { isDarkMode } = useDarkMode();
  const hybridNounsRef = useRef(null);
  const swiperContainerRef = useRef(null);

  // Apply the reusable Swiper logic hook
  useSwiperLogic('hybridNouns', hybridNounsRef, swiperContainerRef, isCurrent);

  const slides = isDarkMode
    ? [
      {
        src: './medias/hybrid-nouns/hybridnouns.wtf_1.webp',
        alt: 'Hybrid Nouns Website',
      },
      {
        src: './medias/hybrid-nouns/hybridnouns.wtf_3.webp',
        alt: 'Hybrid Nouns Website',
      },
      ]
    : [
      {
        src: './medias/hybrid-nouns/hybridnouns.wtf_2.webp',
        alt: 'Hybrid Nouns Website',
      },
      {
        src: './medias/hybrid-nouns/hybridnouns.wtf_4.webp',
        alt: 'Hybrid Nouns Website',
      },
      ];

  // Add the 'swiper-pointer' class if there is more than one slide
  const swiperClass = slides.length > 1 ? 'swiper-pointer' : '';

  return (
    <div className="work border" id="hybridNouns" ref={swiperContainerRef}>
      <div className={`c ${swiperClass}`}>
        <Swiper
          ref={hybridNounsRef}
          loop={true}
          modules={[EffectFade]}
          effect="fade"
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(swiper) => (hybridNounsRef.current = swiper)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <picture>
                <img
                  src={slide.src}
                  alt={slide.alt}
                  loading="lazy"
                />
                <div className="loader">
                  <div className="loader--animation"></div>
                </div>
              </picture>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HybridNouns;
