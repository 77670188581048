import React, { useEffect, useRef } from 'react';
import Header from '../c/Header.js';

const Ipotetu = () => {
  const tocRef = useRef(null);
  const tocPathRef = useRef(null);

  useEffect(() => {
    const toc = tocRef.current;
    const tocPath = tocPathRef.current?.querySelector('path');
    let tocItems = [];

    if (!toc || !tocPath) return;

    const TOP_MARGIN = 0.1;
    const BOTTOM_MARGIN = 0.2;

    let pathLength;
    let lastPathStart;
    let lastPathEnd;

    const drawPath = () => {
      if (!tocPath) return;
      tocItems = Array.from(toc.querySelectorAll('li'));

      tocItems = tocItems.map(item => {
        const anchor = item.querySelector('a');
        const target = document.getElementById(anchor.getAttribute('href').slice(1));

        return {
          listItem: item,
          anchor: anchor,
          target: target,
        };
      });

      tocItems = tocItems.filter(item => !!item.target);

      const path = [];
      let pathIndent;

      tocItems.forEach((item, i) => {
        const x = item.anchor.offsetLeft - 5;
        const y = item.anchor.offsetTop;
        const height = item.anchor.offsetHeight;

        if (i === 0) {
          path.push('M', x, y, 'L', x, y + height);
          item.pathStart = 0;
        } else {
          if (pathIndent !== x) path.push('L', pathIndent, y);

          path.push('L', x, y);

          tocPath.setAttribute('d', path.join(' '));
          item.pathStart = tocPath.getTotalLength() || 0;

          path.push('L', x, y + height);
        }

        pathIndent = x;

        tocPath.setAttribute('d', path.join(' '));
        item.pathEnd = tocPath.getTotalLength();
      });

      pathLength = tocPath.getTotalLength();
      sync();
    };

    const sync = () => {
      if (!tocPath) return;
      const windowHeight = window.innerHeight;

      let pathStart = pathLength;
      let pathEnd = 0;

      let visibleItems = 0;

      tocItems.forEach(item => {
        const targetBounds = item.target.getBoundingClientRect();

        if (
          targetBounds.bottom > windowHeight * TOP_MARGIN &&
          targetBounds.top < windowHeight * (1 - BOTTOM_MARGIN)
        ) {
          pathStart = Math.min(item.pathStart, pathStart);
          pathEnd = Math.max(item.pathEnd, pathEnd);

          visibleItems += 1;

          item.listItem.classList.add('visible');
        } else {
          item.listItem.classList.remove('visible');
        }
      });

      if (visibleItems > 0 && pathStart < pathEnd) {
        if (pathStart !== lastPathStart || pathEnd !== lastPathEnd) {
          tocPath.setAttribute('stroke-dashoffset', '1');
          tocPath.setAttribute(
            'stroke-dasharray',
            `1, ${pathStart}, ${pathEnd - pathStart}, ${pathLength}`
          );
          tocPath.setAttribute('opacity', 1);
        }
      } else {
        tocPath.setAttribute('opacity', 0);
      }

      lastPathStart = pathStart;
      lastPathEnd = pathEnd;
    };

    const handleResize = () => {
      drawPath();
    };

    const handleScroll = () => {
      sync();
    };

    window.addEventListener('resize', handleResize, false);
    window.addEventListener('scroll', handleScroll, false);

    drawPath();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Header />

      <h1>Ipotetu</h1>

      <div id="page">
        <nav className="toc" ref={tocRef}>
          <ul>
            <li><a href="#introduction">Introduction</a></li>
            <li>
              <a href="#content-1">Content 1</a>
              <ul>
                <li><a href="#dev-edit-html">Sub content 1</a></li>
                <li><a href="#dev-element-classes">Sub content 2</a></li>
              </ul>
            </li>
            <li>
              <a href="#content-2">Content 2</a>
              <ul>
                <li><a href="#dev-edit-html">Sub content 1</a></li>
                <li><a href="#dev-element-classes">Sub content 2</a></li>
                <li><a href="#dev-element-classes">Sub content 3</a></li>
              </ul>
            </li>
          </ul>
          <svg className="toc-marker" width="200" height="200" xmlns="http://www.w3.org/2000/svg" ref={tocPathRef}>
            <path
              stroke="#444"
              strokeWidth="3"
              fill="transparent"
              strokeDasharray="0, 0, 0, 1000"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(-0.5, -0.5)"
            />
          </svg>
        </nav>

        <article className="contents">
          <section id="introduction">
            <h2>Introduction</h2>
            <p>Content</p>
          </section>

          <section id="content-1">
            <h2>Content 1</h2>
            <p>Details of Content 1</p>
            <section id="dev-edit-html">
              <h3>Sub content 1</h3>
              <p>Details of Sub content 1</p>
            </section>
            <section id="dev-element-classes">
              <h3>Sub content 2</h3>
              <p>Details of Sub content 2</p>
            </section>
          </section>

          <section id="content-2">
            <h2>Content 2</h2>
            <p>Details of Content 2</p>
            <section id="dev-edit-html">
              <h3>Sub content 1</h3>
              <p>Details of Sub content 1</p>
            </section>
            <section id="dev-element-classes">
              <h3>Sub content 2</h3>
              <p>Details of Sub content 2</p>
            </section>
            <section id="dev-element-classes">
              <h3>Sub content 3</h3>
              <p>Details of Sub content 3</p>
            </section>
          </section>
        </article>
      </div>
    </>
  );
};

export default Ipotetu;
