import React, { useEffect } from 'react';
import Header from '../c/Header.js';
import Items from '../c/Items.js';
import { TypeShuffle } from '../c/typeShuffle';
import {
  anchor
} from "../assets/svgs";

const Home = () => {
  useEffect(() => {
    const links = document.querySelectorAll('a');
    const typeShufflesRef = [];

    // Initialize TypeShuffle for each link
    links.forEach(link => {
      const ts = new TypeShuffle(link);
      typeShufflesRef.push(ts);
      ts.trigger('fx2');
      link.addEventListener('mouseover', () => {
        if (ts) ts.trigger('fx2');
      });
    });

    const handleKeyDown = (e) => {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        typeShufflesRef.forEach(ts => {
          ts.trigger('fx2');
        });
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    const items = document.querySelectorAll('div.item');

    const observerOptions = {
      root: null, // use the viewport as the container
      rootMargin: '0px',
      threshold: 1.0 // Trigger when the entire item is in view
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          typeShufflesRef.forEach(ts => {
            ts.trigger('fx2');
          });
        }
      });
    }, observerOptions);

    items.forEach(item => {
      observer.observe(item);
    });

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      items.forEach(item => {
        observer.unobserve(item);
      });
    };
  }, []);

  return (
    <>
      <Header />

      <section id="works" className="works">
        <div id="worksHeader" className="worksHeader">
          <div>
            <h2 className="title">
              <div>works</div>
              <div>exhibitions</div>
              <div className="anchor down">
               <div dangerouslySetInnerHTML={{ __html: anchor }} />
              </div>
            </h2>
          </div>
        </div>
        <div id="grid">
          <Items />
        </div>
      </section>
    </>
  );
};

export default Home;