import { useState, useEffect } from "react";
import "./assets/scss/sass.scss";
import 'regenerator-runtime/runtime';
import { AnimatePresence } from "framer-motion";
import { Routes, Route } from "react-router-dom";
import { DarkModeProvider } from "./context/DarkModeContext";

import Home from "./pages/Home.js";
import Collection from "./pages/Collection.js";

import Ipotetu from "./pages/Ipotetu.js";
import InternetAestheticsAndWebCultures from "./pages/InternetAestheticsAndWebCultures.js";
import NotFound from "./pages/404.js";

import Lenis from '@studio-freight/lenis';
import Loader from "./components/Loader";

function App() {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    // Initialize Lenis
    const lenis = new Lenis({
      lerp: 0.05,
      smoothWheel: true,
      orientation: 'vertical',
    });
    lenis.on('scroll', (e: Event) => {});
    function raf(time: number) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);

    const checkFontsLoaded = () => {
      document.fonts.ready.then(() => {
        setFontsLoaded(true);
      });
    };

    checkFontsLoaded();
  }, []);

  return (
      <DarkModeProvider>
      <AnimatePresence exitBeforeEnter>
        {!fontsLoaded ? (
          <Loader />
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/" element={<Collection />} /> */}
            <Route path="/ipotetu" element={<Ipotetu />} />
            <Route path="/internet-aesthetics-and-web-cultures" element={<InternetAestheticsAndWebCultures />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </AnimatePresence>
      </DarkModeProvider>
  );
}

export default App;
